
import { createVNode, defineComponent, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import PageModel5 from '@/components/page-model_kefu/index.vue';
import { debounce } from 'lodash-es';
import mqtt_fun from '@/utils/service/mqtt';
import bus from '@/utils/bus';
import {
  close_service_session,
  create_auto_reply_session_record_for_adviser,
  create_session_record_for_adviser,
  query_session_record_list,
  set_session_record_read,
} from '@/api/service-model';
import {
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PictureOutlined,
  ShareAltOutlined,
} from '@ant-design/icons-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { event_obj } from '@/components/page-model_kefu/cerp-beneficiary-config';
import { useI18n } from 'vue-i18n';
import { GET_NO_READ_NUMBER } from '@/store/modules/user/actions';

export default defineComponent({
  setup() {
    const {t} = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
    const bus_obj = bus;
    const sentence: any = ref('');
    const is_auto_reply: any = ref(null);
    const width: any = ref(410);
    const service__name: any = ref('');
    const is_closed: any = ref(true);
    const dataSource: any = ref([]);
    let session_id: any = null;
    const search = reactive({
      current: 1,
      pageSize: 50,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        record: {},
        is_active: true,
        is_search: true,
        cc_bank_account_id: null,
        num: 0,
      },
    });
    const tableHeight = reactive({ y: window.innerHeight - 140, y1: window.innerHeight - 353 });
    const onresize: any = debounce(() => {
      tableHeight.y = window.innerHeight - 140;
      tableHeight.y1 = window.innerHeight - 353;
    }, 200);
    const mqtt_data = reactive({});

    onMounted(() => {
      window.onresize = onresize;
      onresize();
      bus_obj.on('service_read', refresh_service_read); // 客服已读
      bus_obj.on('adviser_read', refresh_adviser_read); // 顾问已读
      bus_obj.on('recieve_session', refresh_recieve_session); // 转客服 或 自己接收
      bus_obj.on('chat_to_adviser', refresh_chat_to_adviser); // 客服发送给顾问
      bus_obj.on('close_session', refresh_close_session); // 关闭会话
      bus_obj.on('chat_to_service', refresh_chat_to_service);  // 顾问发送
      mqtt_fun().channel_subscribe_event('', 'adviser_read');
      mqtt_fun().channel_subscribe_event('', 'chat_to_service');
      mqtt_fun().channel_subscribe_event('', 'service_read');
      mqtt_fun().channel_subscribe_event('', 'recieve_session');
      mqtt_fun().channel_subscribe_event('', 'close_session');
      mqtt_fun().channel_subscribe_event('', 'chat_to_adviser');
    });

    onUnmounted(() => {
      mqtt_fun().channel_unsubscribe_event('', 'adviser_read');
      mqtt_fun().channel_unsubscribe_event('', 'chat_to_service');
      mqtt_fun().channel_unsubscribe_event('', 'service_read');
      mqtt_fun().channel_unsubscribe_event('', 'recieve_session');
      mqtt_fun().channel_unsubscribe_event('', 'close_session');
      mqtt_fun().channel_unsubscribe_event('', 'chat_to_adviser');
      bus_obj.off('service_read', refresh_service_read);
      bus_obj.off('adviser_read', refresh_adviser_read);
      bus_obj.off('recieve_session', refresh_recieve_session);
      bus_obj.off('chat_to_adviser', refresh_chat_to_adviser);
      bus_obj.off('close_session', refresh_close_session);
      bus_obj.off('chat_to_service', refresh_chat_to_service);
    });
    const refresh_service_read = (data: any) => {
      if (data) {
        console.log('客服已读',data);
        if(searchConfig.model.session_id == data.session_id){
          dataSource.value.map((item1: any) => {
              item1.is_service_read = true;
          });
          dataSource.value = ([] as any).concat(dataSource.value);
        }
      }
    };
    const refresh_adviser_read = (data: any) => {
      if (data) {
        console.log('顾问已读',data)
        Object.assign(mqtt_data, { mqtt_type: 'adviser_read', ...data });
        bus.emit('mqtt_update', mqtt_data);
        store.dispatch(`user/${GET_NO_READ_NUMBER}`, {org_id: Number(current_org?.id)})
      }
    };
    const refresh_recieve_session = (data: any) => {
      if (data) {
        console.log('客服接/转客服', data)
        if(searchConfig.model.session_id == data.session_id){
          Object.assign(mqtt_data, { mqtt_type: 'recieve_session', ...data });
          bus.emit('mqtt_update', mqtt_data);
        }else{
          Object.assign(mqtt_data, { mqtt_type: 'recieve_session_update', ...data });
          bus.emit('mqtt_update', mqtt_data);
        }
        store.dispatch(`user/${GET_NO_READ_NUMBER}`, {org_id: Number(current_org?.id)})
      }
    };
    const refresh_chat_to_adviser = (data: any) => {
      if (data) {
        console.log('客服发顾问',data)
        if(searchConfig.model.session_id == data.session_id){
          dataSource.value = dataSource.value.concat([{ ...data.session_record }]);
          goto_timeout();
          Object.assign(mqtt_data, { mqtt_type: 'chat_to_adviser', ...data });
          bus.emit('mqtt_update', mqtt_data);
          set_session_record_read({
            org_id: searchConfig.model.org_id,
            session_id: searchConfig.model.session_id,
            session_record_ids: [data.session_record.id],
          })
        }else{
          Object.assign(mqtt_data, { mqtt_type: 'adviser_read_num', ...data });
          bus.emit('mqtt_update', mqtt_data);
          store.dispatch(`user/${GET_NO_READ_NUMBER}`, {org_id: Number(current_org?.id)})
        }
        goto_timeout();
      }
    };
    const refresh_chat_to_service = (data: any) => {
      if (data) {
        is_auto_reply.value = data.session.is_auto_reply;
        console.log('顾问发送', data);
        Object.assign(mqtt_data, { mqtt_type: 'chat_to_service', ...data });
        bus.emit('mqtt_update', mqtt_data);
        if(searchConfig.model.session_id == data.session_id) {
          dataSource.value = dataSource.value.concat([{...data.session_record}]);
        }
        goto_timeout();
      }
    };
    const refresh_close_session = (data: any) => {
      if (data) {
        console.log('关闭会话',data)
        if(searchConfig.model.session_id == data.session_id) {
          Object.assign(mqtt_data, {mqtt_type: 'close_session', ...data});
          bus.emit('mqtt_update', mqtt_data);
        }
      }
    };

    const searchConfig: any = {
      model: {
        org_id: current_org?.id,
        is_active: true,
        pageSize: 50,
        adviser_user_id: adviser.adviser_user_id,
      }
    }
    const columns: any = [
      {
        title: 'id',
        width: 60,
        dataIndex: 'id',
        autoHeight: true,
        cellComponent: 'name',
        content: (record: any) => {
          if(record.user_id != adviser.adviser_user_id){
            return true;
          }
        },
        cb: (record: any) => {
          if(record.user_id != adviser.adviser_user_id && !record.is_auto_reply){
            return t('page.kefu.name');
          }else if(record.user_id != adviser.adviser_user_id && record.is_auto_reply){
            return t('page.kefu.name1');
          }
        }
      },
      {
        title: 'name',
        minWidth: 150,
        dataIndex: 'name',
        autoHeight: true,
        cellComponent: 'talk',
        content: (record: any) => {
          if(record.user_id != adviser.adviser_user_id){
            return true;
          }
        },
      },
      {
        title: 'content',
        width: 60,
        dataIndex: 'content',
        autoHeight: true,
        cellComponent: 'name',
        content: (record: any) => {
          if(record.user_id == adviser.adviser_user_id){
            return true;
          }
        },
        cb: (record: any) => {
          if(record.user_id == adviser.adviser_user_id){
            return t('page.kefu.adviser');
          }
        }
      },
    ]
    const query_session = () => {
      query_session_record_list({
        ...searchConfig.model,
      }).then((res: any) => {
        dataSource.value.length = 0;
        if(res && res.data && res.data.length > 0){
          let id_list: any = [];
          res.data.map((item: any) => {
            dataSource.value.push({...item});
            if(!item.is_adviser_read){
              id_list.push(item.id);
            }
          })
          if(id_list.length > 0){
            set_session_record_read({
              org_id: searchConfig.model.org_id,
              session_id: searchConfig.model.session_id,
              session_record_ids: id_list,
            })
          }
          if(dataSource.value.length > 49){
            dataSource.value = dataSource.value.sort((a: any, b: any) => a.id - b.id);
            dataSource.value = ([{id: 0, name: dataSource.value[0].name, content: dataSource.value[0].name, start_id: dataSource.value[0].id}] as any).concat(dataSource.value);
          }else{
            dataSource.value = dataSource.value.sort((a: any, b: any) => a.id - b.id);
            dataSource.value = ([] as any).concat(dataSource.value);
          }
        }
        goto_timeout();
      })
    }
    const query_session_more = (id: any) => {
      query_session_record_list({
        start_id: id,
        org_id: searchConfig.model.org_id,
        session_id: searchConfig.model.session_id,
      }).then((res: any) => {
        if(res && res.data && res.data.length > 0){
          let id_list: any = [];
          res.data.map((item: any) => {
            if(!item.is_adviser_read){
              id_list.push(item.id);
            }
          })
          if(id_list.length > 0) {
            set_session_record_read({
              org_id: searchConfig.model.org_id,
              session_id: searchConfig.model.session_id,
              session_record_ids: id_list,
            })
          }
          let list_more: any = res.data.sort((a: any, b: any) => a.id - b.id);
          dataSource.value.splice(0, 1);
          dataSource.value = ( list_more as any).concat(dataSource.value);
          dataSource.value = ([{id: 0, name: dataSource.value[0].name, content: dataSource.value[0].name, start_id: dataSource.value[0].id}] as any).concat(dataSource.value);
        }else{
          message.error(t('page.kefu.No_more_information_loaded'));
        }
      })
    }
    const scgyfangan = reactive({
      title: '会话',
      column_flag: 'beneficiary1',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'cerp-beneficiary1',
      height: tableHeight.y,
      mode: 'radio',
    });

    bus.on('is_auto_reply', (data: any) => {
      scgyfangan.search.requestParams.num += 1;
      is_auto_reply.value = data.is_auto_reply;
    });
    const getFangan = (record: any) => {
      searchConfig.model.session_id = record.id;
      is_auto_reply.value = record.is_auto_reply;
      console.log('is_auto_reply.value', is_auto_reply.value)
        service__name.value = record.service__name;
        is_closed.value = record.is_closed;
      query_session();
    };
    const send_out = () => {
      if (sentence.value) {
        if(is_auto_reply.value){
          create_auto_reply_session_record_for_adviser({
            org_id: current_org.id,
            content: sentence.value,
            message_type: 0,
            session_id: searchConfig.model.session_id,
          }).then((res: any) => {
            if (res) {
              sentence.value = '';
            }
          })
        }else{
          create_session_record_for_adviser({
            org_id: current_org.id,
            content: sentence.value,
            message_type: 0,
            session_id: searchConfig.model.session_id,
          }).then((res: any) => {
            if (res) {
              sentence.value = '';
            }
          })
        }
      } else {
        notification.error({
          message: '温馨提示',
          description:
            '不可发送空白消息',
        });
        sentence.value = '';
      }
    }
    const send_out_enter = (e: any) => {
      if (e.ctrlKey && e.keyCode == 13) {
        sentence.value += '\n';
      } else if (e.shiftKey && e.keyCode == 13) {
        sentence.value += '\n';
      } else if (e.altKey && e.keyCode == 13) {
        sentence.value += '\n';
      } else {
        if (sentence.value != '\n') {
          if(is_auto_reply.value){
            create_auto_reply_session_record_for_adviser({
              org_id: current_org.id,
              content: sentence.value,
              message_type: 0,
              session_id: searchConfig.model.session_id,
            }).then((res: any) => {
              if (res) {
                sentence.value = '';
              }
            })
          }else{
            create_session_record_for_adviser({
              org_id: current_org.id,
              content: sentence.value,
              message_type: 0,
              session_id: searchConfig.model.session_id,
            }).then((res: any) => {
              if (res) {
                sentence.value = '';
              }
            })
          }
        } else {
          notification.error({
            message: '温馨提示',
            description:
              '不可发送空白消息',
          });
          sentence.value = '';
        }
      }
    }
    const handleHttp = (rule: any, value: any) => {
      return new Promise((resolve, reject) => {
        console.log('value', value)
        const regex = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (regex.test(value)) {
          resolve(null);
        } else {
          reject(t('page.kefu.https'));
        }
      });
    }
    const Send_link = () => {
      if(is_closed.value){
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.kefu.Cannot_send_link'),
        });
        return false;
      }
        const current_org = ls.get(STORAGE_CURRENT_ORG_KEY);
        const editModal = reactive({
          visible: true,
          title: t('page.kefu.link'),
          loading: false,
          form: {
            settings: {
              labelAlign: 'right',
              layout: 'vertical',
              col: [],
            },
            fields: [
              {
                type: 'input',
                name: 'name',
                label: '链接',
                label_i18n: 'page.kefu.link',
                disabled: false,
                allowClear: true,
                inputType: 'text',
                defaultValue: '',
                placeholder: '请填写链接内容',
                placeholder_i18n: 'page.kefu.Link_content',
              },
            ],
            rules: {
              name: [
                {required: true, message: t('page.kefu.Link_content')},
                {validator: handleHttp, trigger: ['change']},
              ],
            },
            model: reactive({
              org_id: current_org?.id,
              name: null,
            }),
          },
          options: {},
          ok: (payload: any) => {
            return new Promise(resolve => {
              editModal.loading = true;
              create_session_record_for_adviser({
                org_id: current_org.id,
                content: payload.name,
                message_type: 2,
                session_id: searchConfig.model.session_id,
              }).then((res: any) => {
                if(res){
                  resolve(null);
                }
              }).finally(() => {
                editModal.loading = false;
              })
            });
          },
        });
        modalBox(editModal);
    }
    const UploadFile = () => {
      if(is_closed.value){
        notification.error({
          message: t('page.login.reminder'),
          description: t('page.kefu.Cannot_send_pictures'),
        });
        return false;
      }
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('page.Induction.img'),
        group: '图片',
        type: 'picture-card',
        component: 'upload-image-kefu-modal',
        loading: loading,
        update: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            create_session_record_for_adviser({
              org_id: current_org.id,
              content: payload[0],
              message_type: 1,
              session_id: searchConfig.model.session_id,
            }).then((res: any) => {
              if(res){
                resolve(null);
              }
            }).finally(() => {
              editModal.loading = false;
            })
          });
        },
      });
      modalBox(editModal);
    }
    const close_talk = () => {
      Modal.confirm({
        title: t('page.kefu.Close_session'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            close_service_session({
              org_id: current_org.id,
              service_session_id: searchConfig.model.session_id,
            }).then((res: any) => {
              if(res){
                resolve(null);
              }
            })
          })
        },
        onCancel() {
        },
      })
    }
    const stable: any = ref(null);
    const goto = () => {
      if(dataSource.value && dataSource.value.length > 0){
        stable.value.scrollTo({rowKey: dataSource.value[dataSource.value.length - 1].id});
      }
    }
    const goto_timeout = () => {
      setTimeout(() => {
        goto();
      }, 800);
      setTimeout(() => {
        goto();
      }, 1000);
      setTimeout(() => {
        goto();
      }, 1200);
    }

    const customCell = () => {
      return { style: { background: '#F1F2F3' } };
    };
    const get_width = (num: any) => {
      width.value = num;
    };
    return {
      t,
      dataSource,
      columns,
      send_out,
      send_out_enter,
      is_closed,
      service__name,
      scgyfangan,
      tableHeight,
      stable,
      width,
      get_width,
      goto,
      close_talk,
      UploadFile,
      Send_link,
      getFangan,
      searchConfig,
      event_obj,
      customCell,
      query_session_more,
      sentence,
    };
  },
  components: {
    PageModel5,
    ShareAltOutlined,
    PictureOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
});
