import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "ant-pro-table-list-toolbar" }
const _hoisted_2 = { class: "ant-pro-table-list-toolbar-container" }
const _hoisted_3 = { class: "ant-pro-table-list-toolbar-left" }
const _hoisted_4 = { class: "ant-pro-table-list-toolbar-title" }
const _hoisted_5 = { class: "ant-pro-table-list-toolbar-right" }
const _hoisted_6 = { style: {"height":"200px","width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_model_5 = _resolveComponent("page-model-5")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_s_table = _resolveComponent("s-table")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_PictureOutlined = _resolveComponent("PictureOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_ShareAltOutlined = _resolveComponent("ShareAltOutlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_page_container3 = _resolveComponent("page-container3")!

  return (_openBlock(), _createBlock(_component_page_container3, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_sider, {
            width: _ctx.width,
            style: {"background-color":"#fff"}
          }, {
            default: _withCtx(() => [
              (_ctx.width == 410)
                ? (_openBlock(), _createBlock(_component_page_model_5, {
                    key: 0,
                    modalType: _ctx.scgyfangan.modalType,
                    search: _ctx.scgyfangan.search,
                    showHeader: false,
                    title: _ctx.scgyfangan.title,
                    column_flag: _ctx.scgyfangan.column_flag,
                    height2: _ctx.tableHeight.y,
                    is_pagination: false,
                    rowKey: 'invoice_id',
                    mode: _ctx.scgyfangan.mode,
                    onOk: _ctx.getFangan
                  }, null, 8, ["modalType", "search", "title", "column_flag", "height2", "mode", "onOk"]))
                : _createCommentVNode("", true),
              (_ctx.width == 410)
                ? (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                    key: 1,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.get_width(30))),
                    style: {"font-size":"20px","padding":"5px","cursor":"pointer"}
                  }))
                : _createCommentVNode("", true),
              (_ctx.width == 30)
                ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                    key: 2,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.get_width(410))),
                    style: _normalizeStyle('font-size: 20px;padding: 5px;margin-top:' + _ctx.tableHeight.y +'px; cursor: pointer')
                  }, null, 8, ["style"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["width"]),
          _createVNode(_component_a_layout_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, {
                "body-style": { padding: 0 },
                ref: "elRef"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.service__name ? _ctx.service__name : _ctx.t('page.kefu.name')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_a_space, { align: "center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              size: 'small',
                              disabled: _ctx.is_closed,
                              onClick: _ctx.close_talk
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('page.kefu.close')), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled", "onClick"])
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  (_ctx.dataSource.length > 0)
                    ? (_openBlock(), _createBlock(_component_s_table, {
                        key: 0,
                        height: _ctx.tableHeight.y1,
                        columns: _ctx.columns,
                        "data-source": _ctx.dataSource,
                        scroll: { y: _ctx.tableHeight.y1 },
                        rowKey: 'id',
                        virtual: false,
                        customCell: _ctx.customCell,
                        showHeader: false,
                        pagination: false,
                        ref: "stable"
                      }, {
                        bodyCell: _withCtx(({ column, index, text, record }) => [
                          (column.dataIndex == 'index')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(index + 1 + _ctx.state.pageSize * (_ctx.state.current - 1)), 1)
                              ], 64))
                            : (column.cellComponent)
                              ? (_openBlock(), _createBlock(_resolveDynamicComponent('Cell_' + column.cellComponent), _normalizeProps(_mergeProps({ key: 1 }, {
                  column: column,
                  index: index,
                  text: text,
                  record: record,
                  dataSource: _ctx.dataSource,
                  query_session_more: _ctx.query_session_more,
                })), null, 16))
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 2,
                                  style: _normalizeStyle(column.color ? column.color(record, text) : null)
                                }, _toDisplayString(column.content ? column.content(record, text) : text), 5))
                        ]),
                        _: 1
                      }, 8, ["height", "columns", "data-source", "scroll", "customCell"]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        style: _normalizeStyle(`height: ${_ctx.tableHeight.y1}px;width: 100%;`)
                      }, [
                        _createVNode(_component_a_empty, {
                          description: _ctx.t('page.kefu.chat'),
                          style: {"padding-top":"20%"}
                        }, null, 8, ["description"])
                      ], 4)),
                  _createVNode(_component_a_divider, { style: {"margin-bottom":"2px","margin-top":"2px"} }),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('page.kefu.Send_pictures')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_PictureOutlined, {
                          onClick: _ctx.UploadFile,
                          style: {"font-size":"20px","margin-left":"15px","margin-top":"8px","color":"#999999"}
                        }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('page.kefu.Send_link')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_ShareAltOutlined, {
                          onClick: _ctx.Send_link,
                          style: {"font-size":"20px","margin-left":"15px","margin-top":"8px","color":"#999999"}
                        }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_textarea, {
                      value: _ctx.sentence,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sentence) = $event)),
                      style: {"border":"none !important"},
                      placeholder: _ctx.t('page.kefu.input'),
                      disabled: _ctx.is_closed,
                      "allow-clear": false,
                      onKeyup: _withKeys(_ctx.send_out_enter, ["enter","native"]),
                      autoSize: {
                minRows: 5,
                maxRows: null,
              }
                    }, null, 8, ["value", "placeholder", "disabled", "onKeyup"]),
                    _createVNode(_component_a_button, {
                      style: {"float":"right","margin-top":"10px","margin-right":"15px"},
                      type: "primary",
                      disabled: _ctx.is_closed,
                      onClick: _ctx.send_out
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('page.kefu.send')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ])
                ]),
                _: 1
              }, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}